
import { Component, Vue } from 'vue-property-decorator'
import { ApplyDetail, ExperienceData } from '../../types/experienceApply'

@Component({
  name: 'applyDetail'
})
export default class extends Vue {
  private tableData = []
  private loading = false
  private info: ApplyDetail = {
    applyName: '',
    phone: '',
    email: '',
    companyName: '',
    smName: '',
    labelId: '',
    tenantApplyProductList: [],
    processUserName: '',
    auditTime: '',
    status: '',
    bak: '',
    adminAccount: '',
    versionName: '',
    validDate: '',
    surplusDay: ''
  }

  get applyId () {
    return this.$route.params.id || ''
  }

  created () {
    this.getDetail()
  }

  getData () {
    this.loading = true
    this.$axios.get(this.$apis.experienceApply.selectCustTProductByPage, { labelId: this.info.labelId }).then(res => {
      res.list.forEach((item: ExperienceData) => {
        if (item.type) {
          item.type === '1' ? item.isChoose = true : item.isChoose = false
        }
      })
      this.tableData = res.list || []
      this.tableData.forEach((item: ExperienceData) => {
        this.info.tenantApplyProductList.forEach((items: ExperienceData) => {
          if (item.productId === items.productId) {
            item.isChoose = true
          }
        })
      })
    }).finally(() => {
      this.loading = false
    })
  }

  getDetail () {
    this.$axios.get(this.$apis.experienceApply.selectTenantApplyByApplyId, {
      applyId: this.applyId
    }).then(res => {
      this.info = res
      if (this.info.validDate) {
        const day1 = new Date(this.info.validDate).getTime()
        const nowDay = this.getNowFormatDate()
        const day2 = new Date(nowDay).getTime()
        console.log(day2)
        let day3 = (day1 - day2) / (1000 * 60 * 60 * 24)
        day3 = day3 > 0 ? day3 : 0
        this.info.surplusDay = Math.trunc(day3).toString()
      } else {
        this.info.surplusDay = ''
      }
      this.getData()
    })
  }

  getNowFormatDate () {
    const date = new Date()
    const seperator1 = '-'
    const year = date.getFullYear()
    const month = date.getMonth() + 1
    const strDate = date.getDate()
    const currentdate = year + seperator1 + month + seperator1 + strDate
    return currentdate
  }
}
